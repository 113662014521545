const SoftwareDownloadData = [

    {
        heading: "PROTECTOR.Net Server Software (3.0.1.50)",
        desc: `Upgrading to 3.0 requires a minimum version of 2.10.10 in order to migrate the database. 
        WARNING: If you have a custom integration utilizing the Realtime API, contact us before updating.`,
        image: "/images/600x400/pnet-server-download.png",
        filePath: "protectornet/3.0.1.50/ProtectorNet.exe"
    },
    {
        heading: "HC-ATOM Update Package (3.0.1.50)",
        desc: `Updating HC-ATOM is performed using the System Manager interface`,
        image: "/images/600x400/pnet-atom-download.png",
        filePath: "protectornet/3.0.1.50/ProtectorNet.raucb"
    },
    {
        heading: "PROTECTOR.Net Server Software (2.10.19)",
        desc: `Server software is installed on main PC or host server; all administration is done via Web Browser. 
        Icons are added to start menu for management. Please see the following link for supported Windows OS version. 
        *Now includes multi card format support during the 1 year trial period.*`,
        image: "/images/600x400/pnet-server-download.png",
        filePath: "protectornet/2.10.19/ProtectorNet.exe"
    },
    {
        heading: 'Hartmann Support Tool',
        desc: 'Download as requested by our support team to allow them to provide remote assistance.',
        image: "/images/600x400/hartmann-support.png",
        filePath: "/public/HartmannSupport.exe"
    }

];

export default SoftwareDownloadData;
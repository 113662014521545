import axios from 'axios'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next';
import { FindResponse, ProductItem } from '../../Types';
type Props = {
  show: boolean,
  onHide: any,
  // company:CompanyInfo
  [key: string]: any
}
type CompanyInfo = {
  id:number,
  logo:string,
  logoFull:string,
  companyName:string,
  companyType:string,
  supportBy:string,
  image:string,
  type:string,
  desc:string,
  companyDesc:string
}

export default function IntegrationModal(props: Props) {
  const { t, i18n } = useTranslation();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>(props.company);

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body>
        <div id="integration" className="container ">
          <div className="d-grid gap-7 gap-md-10">

            <div data-aos="fade-up">
              <a className="card bg-white shadow-none" >
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4 order-lg-2 mb-5 mb-lg-0">
                      <div className="d-flex flex-column h-100">
                        <div className="mb-7">
                          <h2 className="card-title h1">{companyInfo?.companyName}</h2>
                          <p className="card-text text-body my-0">Integration type</p>
                          <h5 className='card-text font-weight-bold'>{companyInfo?.type}</h5>
                          <p className="card-text text-body my-0">Supported by</p>
                          <h5 className='card-text font-weight-bold'>{companyInfo?.supportBy}</h5>
                        </div>

                        <div className="card shadow-none mt-auto" >
                          <div className="card-body" style={{backgroundColor:"#e3ecf0",borderRadius:'3%'}}>
                            <figure>
                              {/* <div className="mb-4">
                                <img className="w-75" src={companyInfo?.logoFull} alt="Logo"/>
                              </div> */}
                              <h5 className="card-text font-weight-bold">What is {companyInfo?.companyName}?</h5>
                              <p className='text-muted'>
                                {companyInfo?.companyDesc}
                              </p>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="mb-5">
                        <img className="img-fluid rounded-2" src={companyInfo.image} alt="Image Description"/>
                      </div>

                      <div className="row">
                        {companyInfo!=undefined?
                        <ReactMarkdown className='text-muted'>
                          {companyInfo.desc}
                        </ReactMarkdown>
                        :
                        <div>Loading...</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>
      </Modal.Body>

    </Modal>
  )
}